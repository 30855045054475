<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="3" class="py-1">
                  Tipo
                  <v-autocomplete
                    v-model="filtro.tipo"
                    tabindex="1"
                    :items="tipos"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Cliente
                  <v-text-field
                    v-model.trim="filtro.cliente"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    @blur="buscar_cliente()"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar cliente"
                        small
                        @click="dialog_buscar = true"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col :cols="big ? 12 : 6" :sm="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="filtro.fecha"
                    :limpiar.sync="limpiar_fecha"
                    @changeSize="changeSize"
                  />
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="numero"
        :headers="headers"
        :items="credes"
        :loading="load"
        :search="search"
        :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            icono="fas fa-trash"
            color="error"
            title="Eliminar"
            :texto="`¿Desea eliminar el <strong>${item.tipo} Nº ${item.numero}</strong>? <div style='margin-top: 8px'>(esta acción no se puede deshacer)</div>`"
            :icon_button="true"
            :small="true"
            @action="eliminar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <ModalMayoristas
      v-model="dialog_buscar"
      titulo_nombre="clientes"
      @obtener="set_cliente"
    />
    <CreditoDebito
      v-model="dialog"
      :p_nuevo="nuevo"
      :p_tipos="tipos"
      :p_crede="crede"
      @editar="editar"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import { format_money } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import FechaPickerRango from '../../components/util/FechaPickerRango'
import ModalMayoristas from '../../components/util/ModalMayoristas'
import CreditoDebito from '../../components/auditoria/CreditoDebito'

export default {
  data () {
    return {
      moment: moment,
      panel: 0,
      search: '',
      big: false,
      load: false,
      nuevo: false,
      dialog: false,
      dialog_buscar: false,
      limpiar_fecha: false,
      credes: [],
      tipos: [ 'CREDITO', 'DEBITO' ],
      headers: [
        { text: 'Número', value: 'numero', align: 'end' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Cliente', value: 'cliente_nombre' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Importe', value: 'importe', align: 'end', formatter: format_money },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      crede: {},
      filtro: {
        cliente: null,
        nombre: null,
        fecha: [ null, null ],
        tipo: null
      }
    }
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    FechaPickerRango,
    ModalMayoristas,
    CreditoDebito
  },
  methods: {
    async eliminar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('creditosDebitos/eliminar', {
        tipo: item.tipo,
        numero: item.numero
      })
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // quita elcredito/debito de la lista
          const index = this.credes.indexOf(item)
          this.credes.splice(index, 1)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.credes = []
      await this.$store.dispatch('creditosDebitos/get_creditos_debitos', this.filtro)
        .then((res) => {
          this.credes = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    async buscar_cliente () {
      if (this.filtro.cliente) {
        this.$store.state.loading = true
        await this.$store.dispatch('get_mayoristas', { codigo: this.filtro.cliente })
          .then((res) => {
            this.filtro.nombre = res.item.nombre
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            // limpia los datos
            this.filtro.cliente = null
            this.filtro.nombre = null
          })
        this.$store.state.loading = false
      }
    },
    set_cliente (obj) {
      this.filtro.cliente = obj.codigo
      this.filtro.nombre = obj.nombre
    },
    editar (item) {
      // actualiza el credito/debito en la lista
      let credeb = this.credes.find(cd => cd.numero == item.numero)
      Object.assign(credeb, item)
    },
    open_dialog (item) {
      if (item) {
        this.nuevo = false
        this.crede = JSON.parse(JSON.stringify(item))
      } else {
        this.nuevo = true
        this.crede = {}
      }
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        cliente: null,
        nombre: null,
        fecha: [ null, null ],
        tipo: null
      }
      this.limpiar_fecha = true
    },
    changeSize (custom) {
      this.big = custom
    }
  }
}
</script>